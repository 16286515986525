import React, { useEffect, useState } from 'react';
import Toggle from 'components/toggle';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { useVenue } from 'contexts/venue';
import 'style/venue.scss';
import HiddenCanvas from 'components/hiddenCanvas';
import { useParticipants } from 'contexts/participants';
import moment from 'moment';
import Modal from 'components/modal';
import CurrencyInput from 'react-currency-input-field';
import {formatPhoneNumber} from '_base';

let elementBuffer = [];

function VenueDetails({venue, onDone}) {
    const { elements, setElements, saveVenue,
        selections, setSelections, 
        backgroundImage, setBackgroundImage} = useVenue();
    const { participants, reservations, createParticipant, createReservation, deleteReservation } = useParticipants();
    const [addingElement, setAddingElement] = useState(null);
    const [venueName, setVenueName] = useState(venue.name);
    const [showReservations, setShowReservations] = useState(false);
    const [reservation, setReservation] = useState(null);
    const [participant, setParticipant] = useState(null);
    const [showReservationModal, setShowReservationModal] = useState(false);
    // create/new vendor
    const [createVendor, setCreateVendor] = useState(false);
    const [vendorName, setVendorName] = useState('');
    const [vendorAddress, setVendorAddress] = useState('');
    const [vendorCity, setVendorCity] = useState('');
    const [vendorState, setVendorState] = useState('');
    const [vendorZip, setVendorZip] = useState('');
    const [vendorPhone, setVendorPhone] = useState('');
    const [vendorEmail, setVendorEmail] = useState('');
    // copy space
    const [showCopySpaceModal, setShowCopySpaceModal] = useState(false);
    const [copies, setCopies] = useState(1);
    const [copyDirection, setCopyDirection] = useState('below');
    const venueMap = React.createRef(null);

    useEffect(() => {
        console.log('elements:' , elements);
        elementBuffer = [...elements];
    }, [elements])

    useEffect(() => {
        if (reservation) {
            setParticipant(participants.find(p => p.id == reservation.participantId));
        }
    }, [reservation])

    const back = () => {
        onDone();
    }
    const save = () => {
        clearSelection();
        saveVenue(venueName);
    }
    const addBox = () => {clearSelection();setAddingElement('box')}
    const addCircle = () => {clearSelection();setAddingElement('circle')}
    const copySelected = (numCopies, direction) => {
        if (selections.length === 1) {
            let copies = [];
            selections.forEach((s) => {
                for (var i = 1; i <= numCopies; i++) {
                    let xPos = s.element.x;
                    let yPos = s.element.y;
                    switch(direction) {
                        case 'above': yPos = yPos - (s.element.h * i); break;
                        case 'below': yPos = yPos + (s.element.h * i); break;
                        case 'left': xPos = xPos - (s.element.w * i); break;
                        case 'right': xPos = xPos + (s.element.w * i); break;
                        default: break;
                    }
                    const spaceNum = parseInt(s.element.num) + i;
                    const copy = {...s.element, id: Date.now(), num: spaceNum, isNew: true, x:xPos, y:yPos};
                    copies.push(copy);
                }
            });
            setElements(elements.concat(copies));
            clearSelection();
        }
    }
    
    const mapClick = (evt) => {
        console.log('mapClick')
        if (addingElement) {
            let pos = venueMap.current.getBoundingClientRect();
            let x = (evt.pageX - pos.x) * (1024/ pos.width);
            let y = (evt.pageY - pos.y) * (768/ pos.height);
            let elem = {
                type: addingElement,
                id: Date.now(),
                cost: 0,
                isNew: true,
                venue: venue.id,
                row: '',
                num: 0,
                available: false,
                stroke: '#000',
                fill: addingElement === 'text' ? '#000' : '#FFF',
                label: '',
                x: x,
                y: y,
                w: 10,
                h: 10,
                a: 0
            }
            setAddingElement(null)
            setElements([...elements, elem]);
        } else if (!evt.shiftKey) {
            clearSelection();
        }
    }
    const clearSelection = () => {
        let _selections = [...selections];
        if (_selections.length > 0) {
            _selections.forEach((s) => s.node.setAttribute('stroke', '#000'))
            setSelections([]);
        }
        setReservation(null);
        setParticipant(null);
    }
    const selectElement = (evt, elem, idx) => {
        console.log('selectElement');
        let _selections = [...selections];
        let node = evt.target;
        if (!evt.shiftKey && selections.length > 0) {
            _selections.forEach((s) => s.node.setAttribute('stroke', '#000'))
            _selections = [];
        }
        const selection = {node: node, element: elem, index: idx};
        _selections.push(selection);
        setSelections(_selections);
        evt.target.setAttribute('stroke', '#F00');
        setReservation(reservations.find(r => r.elemId == elem.id));
        evt.preventDefault();
        evt.stopPropagation();
    }
    const move = (direction, value) => {
        if (selections.length > 0) {
            const newValue = parseInt(value);
            selections.forEach((s) => {
                switch(direction) {
                    case 'vertical': s.element.y = newValue; break;
                    case 'horizontal': s.element.x = newValue; break;
                    default: break;
                }
                elementBuffer.splice(s.index, 1, s.element);
            });
            setElements(elementBuffer);
        }
    }
    const rotate = (angle) => {
        if (selections.length > 0) {
            const newAngle = parseInt(angle)
            selections.forEach((s) => {
                s.element.a = newAngle;
                elementBuffer.splice(s.index, 1, s.element);
            });
            setElements(elementBuffer);
        }
    }
    const resize = (direction, value) => {
        if (selections.length > 0) {
            const newValue = parseInt(value);
            selections.forEach((s) => {
                switch(direction) {
                    case 'width': s.element.w = newValue; break;
                    case 'height': s.element.h = newValue; break;
                    default: break;
                }
                elementBuffer.splice(s.index, 1, s.element);
            });
            setElements(elementBuffer);
        }
    }
    const deleteSpaceHandler = () => {
        if (selections.length > 0) {
            let _elements = [...elements];
            selections.forEach((s) => {
                _elements.splice(s.index, 1);
            });
            setElements(_elements);
            clearSelection();
        }
    }
    const cancelReservationHandler = () => {
        deleteReservation(reservation.id);
        clearSelection();
    }
    const editReservationHandler = () => {
        setVendorName(participant.name);
        setVendorAddress(participant.address1);
        setVendorCity(participant.city);
        setVendorState(participant.state);
        setVendorZip(participant.zip);
        setVendorEmail(participant.email);
        setVendorPhone(participant.phone);
        setCreateVendor(true);
        setShowReservationModal(true);
    }
    const saveReservationHandler = () => {
        // create the vendor if needed
        if (createVendor) {
            let data = {
                name: vendorName, 
                address1: vendorAddress, 
                address2: '',
                city: vendorCity, 
                state: vendorState, 
                zip: vendorZip, 
                email: vendorEmail, 
                phone: vendorPhone,
                notes: ''
            }
            if (participant) {
                data.id = participant.id;
            }
            createParticipant(data, selections[0].element.id);
        } else {
            if (reservation !== 0) {
                createReservation(reservation, selections[0].element.id);
            }
        }
        setReservation(null);
        setVendorName('');
        setVendorAddress('');
        setVendorCity('');
        setVendorState('');
        setVendorZip('');
        setVendorEmail('');
        setVendorPhone('');
        setShowReservationModal(false);
        setCreateVendor(false);
        clearSelection();
    }
    const reserveSpaceHandler = () => {
        setReservation(0);
        setShowReservationModal(true);
        setCreateVendor(false);
    }
    const cancelReserveSpaceHandler = () => {
        setReservation(null);
        setVendorName('');
        setVendorAddress('');
        setVendorCity('');
        setVendorState('');
        setVendorZip('');
        setVendorEmail('');
        setVendorPhone('');
        setShowReservationModal(false);
        setCreateVendor(false);
    }
    const reservationExportHandler = () => {
        var csvData = [["name", "address", "city", "state", "zip", "email", "phone", "spaces"]];
        participants.forEach(p => {
            const address = `"${p.address1} ${p.address2}"`
            let spaces = [];
            const res = reservations.filter(r => r.participantId === p.id);
            res.forEach(r => {
                const elem = elements.find(e => e.id == r.elemId);
                if (elem) {
                    spaces.push(elem.row + '-' + elem.num);
                }
            })
            
            csvData.push([p.name, address, p.city, p.state, p.zip, p.email, formatPhoneNumber(p.phone), `"${spaces.join(',')}"`]);
        });

        let csvContent = "data:text/csv;charset=utf-8," 
            + csvData.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
    const createCopiesHandler = () => {
        copySelected(copies, copyDirection);
        setShowCopySpaceModal(false);
        setCopies(1);
        setCopyDirection('below');
    }
    const cancelCopiesHandler = () => {
        setShowCopySpaceModal(false);
        setCopies(1);
        setCopyDirection('below');
    }
    const elementChangeHandler = (attribute, value) => {
        console.log('elementChangeHandler');
        if (selections.length === 1) {
            let _selections = [...selections];
            _selections[0].element[attribute] = value;
            let _elements = [...elements];
            _elements.splice(_selections[0].index, 1, _selections[0].element);
            setElements(_elements);
            setSelections(_selections);
        }
    }
    const changeMapImageHandler = (imageData) => {
        setBackgroundImage(imageData)
    }

    return (
        <div className='contact-details'>
            <div className='contact-settings-panel'>
                <div className='breadcrumb' onClick={back}>
                    <div className='breadcrumb-label'>
                        <FontAwesomeIcon icon={faAngleLeft} /> Back
                    </div>
                </div>
                <div className='contact-name'>{venueName}</div>
                <hr/>
                <label>Name</label>
                <input type='text' value={venueName} onChange={evt => setVenueName(evt.target.value)} maxLength='64' />
                <div style={{marginTop:'10px',display:'flex',justifyContent:'flex-end'}}>
                    <button onClick={save}>Save</button>
                </div>
            </div>
            <div className='contact-communication-panel'>
                <div style={{fontSize:'20px',height:'30px',margin:'10px'}}>Venue Map</div>
                <div className='settingsSection'>
                    <p>Instructions to get started.</p>
                    <ol>
                        <li>Set background image for the venue area.</li>
                        <li>Add boxes or circles to the map by clicking &quot;Add&quot; button and clicking on map location.</li>
                        <li>Setup each space row (eg: A, B, ...), number, and cost, and set weather they are available for purchase.</li>
                    </ol>
                    <p>Editing</p>
                    <ul>
                        <li>Click on a space to select it, the border will turn red.</li>
                        <li>Edit selected spaces from the settings on the right.</li>
                        <li>You can manually reserve spaces or available spaces can be purchased if vendor signup is enabled in the event setup.</li>
                        <li>Click the &quot;Save&quot; button (left) to save the venue and map after making changes.</li>
                    </ul>
                    <div className='mapContainer' style={{
                            cursor:addingElement ? 'crosshair' : 'pointer',
                            backgroundImage:`url(${backgroundImage})`,
                            backgroundSize:'contain',
                            backgroundRepeat:'no-repeat'
                        }}>
                        <svg ref={venueMap} version='1.1' viewBox='0,0,1024,768' tabIndex='0'
                            xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' 
                            onClick={mapClick}>
                                {elements.map((e, idx) => {
                                    const reservation = reservations.find(r => r.elemId == e.id);
                                    const fill = showReservations && reservation ? '#CCC' : '#FFF';
                                    let elem = null;
                                    switch(e.type) {
                                        case 'box': 
                                            elem = <path key={idx} stroke='#000' fill={fill}
                                                d={`M${e.x},${e.y} L${(e.x+e.w)},${e.y} L${(e.x+e.w)},${(e.y+e.h)} L${e.x},${(e.y+e.h)} Z`}
                                                transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null} 
                                                onClick={(evt) => selectElement(evt, e, idx)} />
                                            break;
                                        case 'line':
                                            elem = <path key={idx} stroke='#000' strokeWidth='2'
                                                d={`M${e.x},${e.y} L${e.x+e.w},${e.y}`}
                                                transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null} 
                                                onClick={(evt) => selectElement(evt, e, idx)} />
                                            break;
                                        case 'circle': 
                                            elem = <circle key={idx} stroke='#000' fill={fill} cx={e.x} cy={e.y} r={e.w} 
                                            onClick={(evt) => selectElement(evt, e, idx)} />
                                            break;
                                        case 'text': 
                                            elem = <text key={idx} fill='#000' x={e.x} y={e.y} fontSize='25' 
                                                transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null}
                                                onClick={(evt) => selectElement(evt, e, idx)} >{e.label}</text>
                                            break;
                                        case 'image': 
                                            elem = <image key={idx} xlinkHref={e.href} x={e.x} y={e.y} height={e.h} width={e.w} 
                                            onClick={(evt) => selectElement(evt, e, idx)} />
                                            break;
                                        default: break;
                                    }
                                    return elem;
                                })}
                        </svg>
                    </div>
                    
                </div>
            </div>
            <div className='mapDetailsPanel'>
                <div className='mapDetailArea'>
                    <label>Background</label>
                    <div className='mapDetailSettings'>
                        <HiddenCanvas buttonTitle='Select background image' width={792} height={600} onChange={changeMapImageHandler}/>
                    </div>
                </div>
                <div className='mapDetailArea'>
                    <label>Add space</label>
                    <div className='mapDetailSettings' style={{padding: '10px'}}>
                        <div><button className='linkButton' onClick={addBox}>Add Box</button></div>
                        <div><button className='linkButton' onClick={addCircle}>Add Circle</button></div>
                        {selections.length === 1 &&
                            <div><button className='linkButton' onClick={() => setShowCopySpaceModal(true)}>Copy selected</button></div>
                        }
                    </div>
                </div>
                <div className='mapDetailArea'>
                    <label>Space details</label>
                    {selections.length === 1 ?
                        <div className='mapDetailSettings'>
                            <label>Position</label>
                            <div className='sizeSection'>
                                <div className='sizeSetting'>
                                    <label>Horizontal (x)</label>
                                    <input type='number' min={0} value={selections[0].element.x} onChange={evt => move('horizontal', evt.target.value)} />px
                                </div>
                                <div className='sizeSetting' style={{marginTop:'5px'}}>
                                    <label>Vertical (y)</label>
                                    <input type='number' min={0} value={selections[0].element.y} onChange={evt => move('vertical', evt.target.value)} />px
                                </div>
                            </div>
                            <hr/>
                            <label>Rotate</label>
                            <div className='sizeSection'>
                                <div className='sizeSetting'>
                                    <input type='number' min={-360} max={360} value={selections[0].element.a} onChange={(evt) => rotate(evt.target.value)} />°
                                </div>
                            </div>
                            <hr/>
                            <label>Size</label>
                            <div className='sizeSection'>
                                <div className='sizeSetting'>
                                    <label>Width</label>
                                    <input type='number' min={0} value={selections[0].element.w} onChange={(evt) => resize('width', evt.target.value)} />px
                                </div>
                                <div className='sizeSetting' style={{marginTop:'5px'}}>
                                    <label>Height</label>
                                    <input type='number' min={0} value={selections[0].element.h} onChange={(evt) => resize('height', evt.target.value)} />px
                                </div>
                            </div>
                            <hr/>
                            <label>Row</label>
                            <input type='text' 
                                value={selections[0].element.row} 
                                onChange={(evt) => {elementChangeHandler('row', evt.target.value)}} 
                                placeholder='[string]' 
                                maxLength='32'/>
                            <label>Number</label>
                            <input type='number' 
                                value={selections[0].element.num} 
                                onChange={(evt) => elementChangeHandler('num', evt.target.value)} 
                                placeholder='[number]' min={1} />
                            <label>Cost</label>
                            <CurrencyInput prefix='$'
                            
                                value={selections[0].element.cost}
                                onValueChange={(val) => elementChangeHandler('cost', val)} 
                                placeholder='[$200.00]' />
                            <label>Available for purchase</label> 
                            <Toggle checked={selections[0].element.available} onChange={newVal => elementChangeHandler('available', newVal)}/>
                            {reservation ?
                                <div>
                                    <>
                                    <div>Reserved {moment(reservation.date).format('ddd, MMM DD YYYY')}</div>
                                        {participant && 
                                            <>
                                            <div>{participant.name}</div>
                                            <div>{participant.address1}</div>
                                            <div>{`${participant.city}, ${participant.state} ${participant.zip}`}</div>
                                            <div>Phone: {formatPhoneNumber(participant.phone)}</div>
                                            <div>Email: {participant.email}</div>
                                            </>
                                        }
                                    </>
                                    <div>
                                        <button onClick={cancelReservationHandler}>Cancel reservation</button>
                                        <button onClick={editReservationHandler}>Edit reservation</button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <button onClick={deleteSpaceHandler}>Delete space</button>
                                    <button onClick={reserveSpaceHandler}>Reserve space</button>
                                </div>
                            }
                        </div>
                        :
                        <div className='mapDetailSettings'>
                            <div>Select a space to see its details</div>
                        </div>
                    }
                </div>
                <div className='mapDetailArea'>
                    <label>Reservations</label>
                    <div className='mapDetailSettings'>
                        <label>Shade reserved spaces</label>
                        <Toggle checked={showReservations} onChange={newVal => setShowReservations(newVal)}/>
                        <div><button className='linkButton' onClick={reservationExportHandler}>Export reservation list</button></div>
                    </div>
                </div>
            </div>
            <Modal 
                title='Reserve space' showing={showReservationModal} 
                onHide={() => {setShowReservationModal(false)}}
                buttons={[
                    {type: 'primary', handler:() => saveReservationHandler(), label: 'Save reservation', 
                        disabled: !createVendor ? reservation == 0 : vendorName.length == 0},
                    {type: 'secondary', handler:() => cancelReserveSpaceHandler(), label: 'Cancel'}
            ]}>
                    {!createVendor ?
                        <>
                            {selections.length > 0 &&
                                <p>Select a vendor for row {selections[0].element.row} space {selections[0].element.num}</p>
                            }
                            <select value={reservation} onChange={evt => setReservation(evt.target.value)}>
                                <option value='0'>-select vendor-</option>
                                {participants.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                            </select>
                            <button className='linkButton' onClick={() => setCreateVendor(true)}>Create a new vendor</button>
                        </>
                        : <>
                            {selections.length > 0 &&
                                <p>Create a vendor for row {selections[0].element.row} space {selections[0].element.num}</p>
                            }
                            <div className='createVendorFields'>
                                <label>Name</label>
                                <input type='text' value={vendorName} onChange={evt => setVendorName(evt.target.value)} maxLength={48} />
                                <label>Address</label>
                                <input type='text' value={vendorAddress} onChange={evt => setVendorAddress(evt.target.value)} maxLength={48} />
                                <label>City</label>
                                <input type='text' value={vendorCity} onChange={evt => setVendorCity(evt.target.value)} maxLength={24} />
                                <label>State Abbreviation</label>
                                <input type='text' value={vendorState} onChange={evt => setVendorState(evt.target.value)} maxLength={2} />
                                <label>Postal code</label>
                                <input type='text' value={vendorZip} onChange={evt => setVendorZip(evt.target.value)} maxLength={10} />
                                <label>Phone</label>
                                <input type='text' value={vendorPhone} onChange={evt => setVendorPhone(evt.target.value)} maxLength={12} />
                                <label>Email</label>
                                <input type='text' value={vendorEmail} onChange={evt => setVendorEmail(evt.target.value)} maxLength={48} />
                            </div>
                            {!participant && 
                                <button className='linkButton' onClick={() => setCreateVendor(false)}>Select existing vendor</button>
                            }
                        </>
                    }
            </Modal>
            <Modal 
                title='Copy space' 
                showing={showCopySpaceModal}
                onHide={() => setShowCopySpaceModal(false)}
                buttons={[
                    {type: 'primary', handler:() => createCopiesHandler(), label: 'Make copies'},
                    {type: 'secondary', handler:() => cancelCopiesHandler(), label: 'Cancel'}
                ]}>

                <div className='createVendorFields'>
                    <label>Number of copies</label>
                    <input type='number' min={1} value={copies} onChange={evt => setCopies(evt.target.value)} />
                    <label>Placement of copies</label>
                    <select value={copyDirection} onChange={evt => setCopyDirection(evt.target.value)}>
                        <option value='above'>Above</option>
                        <option value='below'>Below</option>
                        <option value='left'>Left</option>
                        <option value='right'>Right</option>
                    </select>
                </div>
            </Modal>
        </div>
    )
}

export default VenueDetails;