import React, { useEffect, useState } from 'react';
import Logins from '../../apps/logins/logins';
import Account from './account';
import Portal from 'routes/settings/portal';
import Subscription from './subscription';
import TenantSettings from 'routes/settings/tenantSettings';
import { useAuth } from 'contexts/auth';
import { LoginsProvider } from 'contexts/logins';
import { TenantProvider } from 'contexts/tenant';
import { AuditLogProvider } from 'contexts/auditLog';
import AuditLog from './auditLog';
import { SubscriptionTypesProvider } from 'contexts/subscriptionTypes';
import { SubscriptionProvider } from 'contexts/subscription';
import { MemberTypesProvider } from 'contexts/memberTypes';
import { EmailTemplatesProvider } from 'contexts/emailTemplates';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrowser, faCogs, faFileAlt, faQuestionCircle, faSyncAlt, faUserCircle } from '@fortawesome/pro-regular-svg-icons';

export async function loader({params}) {
    return params;
}

function Settings() {
    const { tenant } = useAuth();
    const loaderData = useLoaderData();
    const [view, setView] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (loaderData) {
            setView(loaderData.view ? loaderData.view : null);
        }
    }, [loaderData])

    const getTitle = () => {
        return view === 'logins' ? 'Logins' : 
            view === 'account' ? 'Account Info' : 
            view === 'portal' ? 'Member Portal' : 
            view === 'audit' ? 'Audit Log' :
            view === 'subscription' ? 'Subscription' :
            view === 'System Settings';
    }

    return(
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Administration {view && ': ' + getTitle()}</div>
                </div>
            </div>
            {!view &&
                <div className='showLanding'>
                    <div className='showLandingTile' onClick={() => navigate('/admin/admin/logins')}>
                        <FontAwesomeIcon icon={faUserCircle} size='6x'/>
                        <h6>Logins</h6>
                    </div>
                    <div className='showLandingTile' onClick={() => navigate('/admin/admin/account')}>
                        <FontAwesomeIcon icon={faQuestionCircle} size='6x'/>
                        <h6>Account Info</h6>
                    </div>
                    <div className='showLandingTile' onClick={() => navigate('/admin/admin/portal')}>
                        <FontAwesomeIcon icon={faBrowser} size='6x'/>
                        <h6>Member Portal</h6>
                    </div>
                    <div className='showLandingTile' onClick={() => navigate('/admin/admin/audit')}>
                        <FontAwesomeIcon icon={faFileAlt} size='6x'/>
                        <h6>Audit log</h6>
                    </div>
                    <div className='showLandingTile' onClick={() => navigate('/admin/admin/subscription')}>
                        <FontAwesomeIcon icon={faSyncAlt} size='6x'/>
                        <h6>Subscription</h6>
                    </div>
                    <div className='showLandingTile' onClick={() => navigate('/admin/admin/system')}>
                        <FontAwesomeIcon icon={faCogs} size='6x'/>
                        <h6>System settings</h6>
                    </div>
                </div>
            }
            {view === 'logins' && <LoginsProvider type='account' resourceId={tenant.id}><Logins type='account' resourceId={tenant.id} /></LoginsProvider>}
            {view === 'account' && 
                <TenantProvider tenantId={tenant.id}>
                    <Account />
                </TenantProvider>}
            {view === 'portal' && <Portal />}
            {view === 'audit' && <AuditLogProvider><AuditLog /></AuditLogProvider>}
            {view === 'subscription' && 
                <SubscriptionTypesProvider groupId={tenant.affiliate}>
                    <SubscriptionProvider>
                        <Subscription />
                    </SubscriptionProvider>
                </SubscriptionTypesProvider>}
            {view === 'system' && 
                <MemberTypesProvider>
                    <EmailTemplatesProvider>
                        <TenantSettings />
                    </EmailTemplatesProvider>
                </MemberTypesProvider>}
        </div>
    )
}

export default Settings;