import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import Core from 'util/core';

export async function loader({params}) {
    return params;
}

function Unsubscribe() {
    const loaderData = useLoaderData();
    const [message, setMessage] = useState('Unsubscribing....');

    useEffect(() => {
        if (loaderData.email && loaderData.email.length > 0) {
            let data = {email: loaderData.email};
            Core.runNoAuthAction('unsubscribe', data, (response) => {
                if (response && response.success) {
                    setMessage("The email address " + loaderData.email + " has been un-subscribed and will no longer receive emails from middletwin.");
                } else {
                    setMessage("The email address " + loaderData.email + " is already unsubscribed.");
                }
            });
        } else {
            setMessage('ERROR: Invalid or missing email');
        }
    }, [])

    return (
        <div>
            <div style={{textAlign:'center',marginTop:'100px',fontSize:'24px',fontWeight:'bold'}}>
                <p><span >{message}</span></p>
                <p>You can close this window when finished.</p>
            </div>
        </div>
    )
}

export default Unsubscribe;